import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
//STYLES
import useStyles from './style';

export default function TabPanel(props) {
  
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <Grid className={classes.root} container direction="column"  justify="flex-start"  alignItems="stretch">
      <Container maxWidth="lg" className={classes.container} >
        {children}
      </Container>
    </Grid>
  );
}
