import React, { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { Db } from '../firebase';
import { useUser } from './auth'

const MetricsContext = createContext();

export function MetricsProvider(props) {
  const [metrics, setMetrics] = useState(null)
  const [loadingMetrics, setLoadingMetrics] = useState(true)  

  useEffect(() => {
    const unsubscribe = Db.collection('ciduMetrics').doc('ciduProjectsMetrics').onSnapshot( doc => {
      try {
        const currentMetrics = doc.data()   
        setMetrics(currentMetrics)
      } catch (e) { console.log("Error from MetricsContext: ", e)
      } finally {
        setLoadingMetrics(false)
      }
    })
    return unsubscribe;
  }, [])
   

  const value = useMemo(() => {
    return({
      metrics, 
      setMetrics,
      loadingMetrics,
      setLoadingMetrics,
    })
  }, [metrics, loadingMetrics])

  return <MetricsContext.Provider value={value} {...props}/>  
}

// Custom hook that shorthands the context!
export function useMetrics(){
  const context = useContext(MetricsContext)
  if (!context){
    throw new Error("useMetrics debe estar dentro del proveedor");
  }
  return context
}
