import React, { useState, useEffect } from 'react'
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import TapPanel from '../../components/tapPanel'
import Modal from '../../components/dialog';
import Table from '@material-ui/core/Table';
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import TableBody from '@material-ui/core/TableBody';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab'; 
import Loader from '../../components/loader'
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tab from '@material-ui/core/Tab';
import jwt from 'jsonwebtoken';
//ID
import { v4 as uuidv4 } from 'uuid';
//LOADUNDING COMPONENT  
import Loading from "../../components/loading"; 
//DOWNLOAD
import downloadFile from 'js-file-download';
//AXIOS
import axios from 'axios';
//CHARTS
import { Line, Doughnut } from 'react-chartjs-2';
//FIREBASE
import { Functions, Db, Storage} from '../../firebase'
//CONTEXT
import { useUser } from '../../context/auth'
import { useMetrics } from '../../context/metrics'
//STYLES
import { withStyles } from '@material-ui/core/styles';
import useStyles from './style';
 

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f5f5f5',
    },
  },
}))(TableRow);


const initialState = {
  isUpdating: false, 
  uid: '',
  //USER DATA
  photoLastUrl: '',
  photoUrlName: '', 
  photoUrl: '',
  photoUrlError: false,
  photoUrlErrorMessage: '',
  userPhoto: '',
  userPhotoError: false,
  userPhotoErrorMessage: '', 
  userName: '',
  userNameError: false,
  userNameErrorMessage: '', 
  userMail: '',
  userMailError: false,
  userMailErrorMessage: '',
  userId: '',
  userIdError: false,
  userIdErrorMessage: '',
  userTypeId: '',
  userTypeIdError: false,
  userTypeIdErrorMessage: '',
  userPhone: '',
  userPhoneError: false,
  userPhoneErrorMessage: '', 
  userState: false,
  userStateError: false,
  userStateErrorMessage: '',
  userRole: '', 
  userRoleError: false,
  userRoleErrorMessage: '',
  userRaa: '',
  userRaaError: false,
  userRaaErrorMessage: '',
  //Estado del modal
  statemodal: false,
  isLoading: true,
  isSuccesfull: false,
  isUnsuccessful: false,
  isBadRequest: false,
  titleModal: 'Procesando',
  contentmodal: 'Cargando...', 
}


export default function Dashboard(){

  const classes = useStyles();
  const { user, logout } = useUser()
  const { metrics } = useMetrics()
  //Stater of tabs
  const [value, setValue] = useState(0);  
  //List of users
  const [users, setUsers] = useState([]); 
  //NewUser modal Data
  const [newUserModal, setNewUserModal] = useState(false); 
  //Loading spinner of user
  const [loading, setLoading] = useState(true)
  //Item to load and delete user
  const [userDelete, setUserDelete] = useState({
    itemDelete: {},
    userId: '',
    stateModalDelete: false, 
  })
  //State od inputs
  const [userData, setUserData] = useState(initialState); 
  //Spinner modal to load and set data
  const [loadingModal, setLoadingModal] = useState(false); 
  
  //DOCUMENTS TO DOWNLOAD
  const downloadDocuments = [
    {
      name: 'Formato Avalúos', 
      prefix: 'legal/',
      documentName: 'FINALFORM.xlsx',
      id: '0'
    },
    {
      name: 'Certificado Bancolombia', 
      prefix: 'legal/',
      documentName: 'CTA BANCOLOMBIA CIDU.pdf',
      id: '1'
    },
    {
      name: 'Rut Color', 
      prefix: 'legal/',
      documentName: 'RUT - CIDU SAS.pdf',
      id: '2'
    },
    {
      name: 'Firma Digital', 
      prefix: 'legal/',
      documentName: 'jonathan.png',
      id: '3'
    },
    {
      name: 'Terminos y Condiciones Apple', 
      prefix: 'legal/',
      documentName: 'Apple_Developer_Program_License_Agreement_XV2A27GUJ6.pdf',
      id: '4'
    },
    {
      name: 'Logo Cidu', 
      prefix: 'legal/Assets/logos/',
      documentName: 'logo1024.png',
      id: '5'
    }, 
    {
      name: 'Logo Cidu Redondo', 
      prefix: 'legal/Assets/logos/',
      documentName: 'circle1024.png',
      id: '6'
    },
    {
      name: 'Logo Cidu Sin Fondo', 
      prefix: 'legal/Assets/logos/',
      documentName: 'logoCidu-1.png',
      id: '7'
    },
    {
      name: 'Logo Cidu Horizontal', 
      prefix: 'legal/Assets/logos/',
      documentName: 'Imagen1.png',
      id: '8'
    }, 
    {
      name: 'Imagenes Fondo', 
      prefix: 'legal/Assets/socialManager/',
      documentName: 'social.zip',
      id: '9'
    }, 
    {
      name: 'Claves RepoApp', 
      prefix: 'legal/Assets/keys/',
      documentName: 'keys.zip',
      id: '10'
    },  

    
  ]
  
  //LOAD AND WATCHE OF USER CIDU LIST
  useEffect(() => {
    const unsubscribe = Db.collection('ciduUsers').onSnapshot( query => {
      const currentsUsers = [];
      query.forEach(doc => { 
        const currentUser = doc.data()
        if(currentUser.email !== 'direccion@cidu.com.co') {
          const userName = currentUser.displayName
          const userCodeId = `${currentUser.userTypeId} ${currentUser.userId}` 
          const userMail = currentUser.email
          const userRole = currentUser.userRole
          const userPhone = currentUser.phoneNumber.replace('+57', '')
          const userPhoto = currentUser.userPhoto
          const photoUrl = currentUser.photoUrl
          const photoUrlName = currentUser.photoUrlName
          const userRaa = currentUser.userRaa
          const userState = !currentUser.disabled
          currentsUsers.push({
            userPhoto: userPhoto,
            photoUrl: photoUrl, 
            photoUrlName: photoUrlName,
            userName: userName, 
            userCodeId: userCodeId, 
            userMail: userMail, 
            userRole: userRole, 
            userPhone: userPhone,
            userRaa: userRaa, 
            userState: userState,
            ...currentUser
          })
        }
      }) 
      //console.log(currentsUsers)
      setUsers(currentsUsers)
      setLoading(false)
    })
    return unsubscribe;
  }, [])

  //CHANGE OF TAB
  const handleTabChange = (e, newValue) => setValue(newValue); 

  //DELETE USER
  const deleteUser = (deleteData) => {
    const data = deleteData;
    const item = data.userName.toUpperCase()
    setUserDelete(() => ({
      itemDelete: data,
      userId: item,
      stateModalDelete: true, 
    })) 
  };

  //CONFIRM DELETE USER
  const confirmDeleteUser = async () => {
    try{
      setUserDelete(prevState => ({ 
        ...prevState,
        stateModalDelete: false, 
      }))
      setUserData(prevState => ({
        ...prevState,  
        statemodal: true, 
        isLoading: true,
        isSuccesfull: false,
        isUnsuccessful: false,
        isBadRequest: false,
        titleModal: 'Cargando...',
        contentmodal: `Eliminando Usuario ${userDelete.userId}`, 
      })); 
      const data = {
        uid: userDelete.itemDelete.uid
      } 
      //console.log('data', data)
      const sendData = Functions.httpsCallable('deleteSelectUser');
      const statusMail = await sendData(data);  
      if(statusMail.data.statusCode === 200){ 
        setUserData(prevState => ({
          ...prevState,  
          statemodal: true, 
          isLoading: false,
          isSuccesfull: true,
          isUnsuccessful: false,
          isBadRequest: false,
          titleModal: 'Exito',
          contentmodal: 'El usuario ha sido eliminado', 
        }));  

        setLoading(true)
        setTimeout(() => setLoading(false), 5000);
      }else{ 
        setUserData(prevState => ({
          ...prevState,  
          statemodal: true, 
          isLoading: false,
          isSuccesfull: false,
          isUnsuccessful: true,
          isBadRequest: false,
          titleModal: 'Advertencia',
          contentmodal: 'No se ha podido eliminar el Usuario, intentelo de nuevo'
        }));
      } 
      setUserDelete(() => ({
        itemDelete: {},
        userId: '',
        stateModalDelete: false, 
      }))

    }catch(e){
      console.log('Error to delete user', e) 
      setUserData(initialState);
      setUserData(prevState => ({
        ...prevState,
        statemodal: true,   
        isLoading: false,
        isSuccesfull: false,
        isUnsuccessful: false,
        isBadRequest: true,
        titleModal: 'Error',
        contentmodal: 'Ocurrió un errror y no se ha podido eliminar el usuario, por favor intentelo de nuevo'
      })); 
    }
  }

  //CANCEL DELETE USERR
  const cancelDeleteser = () => {
    try{
      setUserDelete(() => ({
        itemDelete: {},
        userId: '',
        stateModalDelete: false, 
      }))
    }catch(e){
      console.log('Error to delete user', e)
    }
  }

  //UPDATE USER
  const loadUserUpdate = user => {
    try{  
      setUserData(prevState => ({
        ...prevState, 
        isUpdating: true, 
        uid: user.uid,
        userName: user.userName,  
        userPhoto: user.userPhoto,
        photoUrl: user.photoUrl,
        photoLastUrl: user.photoUrl,
        photoUrlName: user.photoUrlName, 
        userMail: user.userMail, 
        userId: user.userId, 
        userRole: user.userRole,
        userTypeId: user.userTypeId, 
        userPhone: user.userPhone, 
        userRaa: user.userRaa, 
        userState: user.userState, 
      }));
      toggleNewUserModal()
    }catch(e){
      console.log('Error to update user', e)
    }
    
  };

  //CREATE A NEW USER
  const createNewUser = () => {
    setNewUserModal(!newUserModal)
    setUserData(prevState => ({
      ...prevState, 
      isUpdating: false,  
    }));
  }
 
  //TOGGLE NEW USER MODAL
  const toggleNewUserModal = () => setNewUserModal(!newUserModal)

  //CLOSE NEW USER MODALS
  const closeNewUserModal = () => {
    clearData()
    toggleNewUserModal()
  }

  //CLEAR INPUTS
  const clearData = async () => setUserData(initialState)

  //TOGGLE MODAL NEW USER
  const toggleModalNewUser = () => {
    setUserData(prevState => ({
      ...prevState, 
      statemodal : !prevState.statemodal
    }));
  }

  //TOGGLE MODAL OF DOWNLOAD
  const toggleModal = () => setLoadingModal(!loadingModal) 

  //CREATE A URL TO DOWNLOAD FILE
  const createUrldownload = async (e) => {
    try{  
      setLoadingModal(true)
      const documentName = e.documentName
      const documentUbication = `${e.prefix}${documentName}`
      const ciduToken = jwt.sign({ nameFile : documentUbication }, process.env.REACT_APP_KEY_JWT_CIDU_FILES); 
      const url = `https://cidu.com.co/api/downloadS3?jwtkeys=${ciduToken}`
      const res = await axios.post(url , {},  
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }); 
      var data = await res.data     
      download(data.data, documentName || 'ciduDocument')
    }catch(e){
      console.log('Error to download', e)
    }
  }

  //DOWNLOAD FILE
  const download = async (urlFile, nameFile) => {
    try{   
      const response = await axios({
        url: urlFile ,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json', 
        },
        method: 'GET', 
      })  
      downloadFile(response.data, nameFile);  
      setLoadingModal(false)
    }catch(e){
      console.log('Error to download', e)
    }
  }
  
  //CLOSE SESSION
  const closeSesion = async () => await logout()

  //SEND REQUEST
  const sendNewUser = async () => {
    try{
      const validate = await validateData()
      if(!validate){  
        const data = { 
          userName: userData.userName, 
          userPhoto: userData.userPhoto, 
          photoUrlName: userData.photoUrlName,
          photoUrl: userData.photoUrl, 
          userMail: userData.userMail,
          userId: userData.userId,
          userTypeId: userData.userTypeId,
          userState: userData.userState,
          userRole: userData.userRole,
          userPhone: userData.userPhone,
          userRaa: userData.userRaa,
          acepta: true,
          isFederate: false, 
          isGoogle: false,
          isFacebook: false,
          isPassword: true, 
          isNewUser: true, 
          providerId: ["password"]
        };
        setUserData(prevState => ({
          ...prevState,  
          statemodal: true, 
          isLoading: true,
          isSuccesfull: false,
          isUnsuccessful: false,
          isBadRequest: false,
          titleModal: 'Cargando...',
          contentmodal: userData.isUpdating ? 'Actualizando Usuario' : 'Creando Usuario', 
        })); 
        toggleNewUserModal() 
        if(userData.isUpdating){
          data.uid = userData.uid; 
          if(userData.photoUrl !== userData.photoLastUrl){
            if(userData.userRole === "coadministrador" || userData.userRole === "administrador"){ 
              const uidPhoto = uuidv4();
              const photoName = `${uidPhoto}-${userData.userPhoto.name}`;
              const urlReFirms = `/assets/firms/${photoName}`;
              const storageRef = Storage.ref();
              const firmRef = storageRef.child(urlReFirms);
              await firmRef.put(userData.userPhoto);
              data.photoUrl = await firmRef.getDownloadURL(); 
              data.photoUrlName = photoName;
              if(userData.photoLastUrl.startsWith('https://firebasestorage')){ 
                const desertRef = Storage.refFromURL(userData.photoLastUrl);
                await desertRef.delete()
              }
           }
          }
        }else{
          if(userData.userRole === "coadministrador" || userData.userRole === "administrador"){
            const uidPhoto = uuidv4();
            const photoName = `${uidPhoto}-${userData.userPhoto.name}`;
            const urlReFirms = `/assets/firms/${photoName}`;
            const storageRef = Storage.ref();
            const firmRef = storageRef.child(urlReFirms);
            await firmRef.put(userData.userPhoto);
            data.photoUrl = await firmRef.getDownloadURL(); 
            data.photoUrlName = photoName;
          }
        }    
        if(userData.isUpdating){ 
          //console.log(data)
          const sendData = Functions.httpsCallable('updateUserByAdmin');
          const statusMail = await sendData(data);  
          if(statusMail.data.statusCode === 200){ 
            setUserData(prevState => ({
              ...prevState,  
              statemodal: true, 
              isLoading: false,
              isSuccesfull: true,
              isUnsuccessful: false,
              isBadRequest: false,
              titleModal: 'Exito',
              contentmodal: 'El usuario ha sido actualizado', 
            }));  
          }else{ 
            setUserData(prevState => ({
              ...prevState,  
              statemodal: true, 
              isLoading: false,
              isSuccesfull: false,
              isUnsuccessful: true,
              isBadRequest: false,
              titleModal: 'Advertencia',
              contentmodal: 'El usuario no ha sido actualizado, intentelo de nuevo'
            }));
          } 
        }else{ 
          //SI ES PARA CREAR UN NUEVO USUARIO  
          const sendData = Functions.httpsCallable('createNewUserByAdmin');
          const statusMail = await sendData(data);  
          if(statusMail.data.statusCode === 200){ 
            setUserData(prevState => ({
              ...prevState,  
              statemodal: true, 
              isLoading: false,
              isSuccesfull: true,
              isUnsuccessful: false,
              isBadRequest: false,
              titleModal: 'Exito',
              contentmodal: 'El usuario ha sido creado', 
            }));  
          }else{ 
            setUserData(prevState => ({
              ...prevState,  
              statemodal: true, 
              isLoading: false,
              isSuccesfull: false,
              isUnsuccessful: true,
              isBadRequest: false,
              titleModal: 'Advertencia',
              contentmodal: 'No se ha podido crear el Usuario, intentelo de nuevo'
            }));
          } 
        } 
      } 
    }catch(e){
      console.log('Error to create a new user on CIDU APP', e) 
      toggleNewUserModal() 
      setUserData(initialState);
      setUserData(prevState => ({
        ...prevState,
        statemodal: true,   
        isLoading: false,
        isSuccesfull: false,
        isUnsuccessful: false,
        isBadRequest: true,
        titleModal: 'Error',
        contentmodal: 'Ocurrió un errror y no se ha podido crear el usuario, por favor intentelo de nuevo'
      }));  
    }
  } 
   
  //VALIDATE INPUT OF NEW USER
  const validateData = async () => { 
    let hayErrores = false;
    let re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let numbers = /^[0-9]+$/;

    //Nombre usuario
    if (userData.userName.length > 0) { 
      setUserData(prevState => ({
        ...prevState,
        userNameError: false,
        userNameErrorMessage: "",
      }))  
    } else { 
      setUserData(prevState => ({
        ...prevState,
        userNameError: true,
        userNameErrorMessage: "Ingrese su nombre completo",
      }))  
      hayErrores = true;
    } 
    //Correo
    if (re.test(userData.userMail)) { 
      setUserData(prevState => ({
        ...prevState,
        userMailError: false,
        userMailErrorMessage: "",
      }))  
    } else {  
      setUserData(prevState => ({
        ...prevState,
        userMailError: true,
        userMailErrorMessage: "Ingrese su correo",
      }))  
      hayErrores = true;
    }
    //Type Id
    if (userData.userTypeId !== "") {
      setUserData(prevState => ({
        ...prevState,
        userTypeIdError: false,
        userTypeIdErrorMessage: "",
      }))   
    } else { 
      setUserData(prevState => ({
        ...prevState,
        userTypeIdError: true,
        userTypeIdErrorMessage: "Seleccione un tipo de Identificación",
      })) 
      hayErrores = true;
    }
    //Id
    if (numbers.test(userData.userId) && userData.userId.length > 0) { 
      setUserData(prevState => ({
        ...prevState,
        userIdError: false,
        userIdErrorMessage: "",
      })) 
    } else { 
      setUserData(prevState => ({
        ...prevState,
        userIdError: true,
        userIdErrorMessage: "Ingrese número de identificación sin puntos o comas",
      })) 
      hayErrores = true;
    }
    //Rol
    if (userData.userRole !== "") {
      setUserData(prevState => ({
        ...prevState,
        userRoleError: false,
        userRoleErrorMessage: "",
      }))   
    } else { 
      setUserData(prevState => ({
        ...prevState,
        userRoleError: true,
        userRoleErrorMessage: "Seleccione un rol",
      })) 
      hayErrores = true;
    }
    //Telefono
    if (numbers.test(userData.userPhone) && userData.userPhone.length >= 10) { 
      setUserData(prevState => ({
        ...prevState,
        userPhoneError: false,
        userPhoneErrorMessage: "",
      })) 
    } else { 
      setUserData(prevState => ({
        ...prevState,
        userPhoneError: true,
        userPhoneErrorMessage: "Ingrese numero de teléfono",
      })) 
      hayErrores = true;
    }  
    if(userData.userRole === "coadministrador" || userData.userRole === "administrador"){
      //Codigo RAA
      if (userData.userRaa.length > 0) { 
        setUserData(prevState => ({
          ...prevState,
          userRaaError: false,
          userRaaErrorMessage: "",
        })) 
      } else { 
        setUserData(prevState => ({
          ...prevState,
          userRaaError: true,
          userRaaErrorMessage: "Ingrese código RAA",
        })) 
        hayErrores = true;
      } 
      //FIRMA DEL USUARIO AVALUADOR
      if (userData.photoUrl.length > 0) { 
        setUserData(prevState => ({
          ...prevState,
          userPhotoError: false,
          userPhotoErrorMessage: "",
        })) 
      } else { 
        setUserData(prevState => ({
          ...prevState,
          userPhotoError: true,
          userPhotoErrorMessage: "Ingrese Firma de Usuario",
        })) 
        hayErrores = true;
      } 
    }
    return hayErrores;
  }

  //CHANGE INPUT TEXT 
  const handleChange = async (e) => {
    const { name, value } = e.target;
    //console.log(e.target.type, name, value)
    if(e.target.type === 'file'){
      if(value){
        //console.log(e.target.files[0])
        let reader = new FileReader()
        reader.onload = (event) => { 
          setUserData(prevState => ({
            ...prevState,
            photoUrl: e.target.files.length > 0 ? e.target.files[0].type === 'image/png' ? event.target.result : prevState.userPhoto : prevState.userPhoto
          }));
        }
        reader.readAsDataURL(e.target.files[0])
        setUserData(prevState => ({
          ...prevState,
          [name]: e.target.files.length > 0 ? e.target.files[0].type === 'image/png' ? e.target.files[0] : prevState.userPhoto : prevState.userPhoto
        })); 
      } 
      // else{ 
      //   setUserData(prevState  => ({
      //     ...prevState,
      //     photoUrl: prevState.photoUrl,
      //     userPhoto: prevState.userPhoto
      //   })); 
      // }
    }else{
      setUserData(prevState => ({
        ...prevState,
        [name]: e.target.type === "checkbox" ? e.target.checked : value
      }));
    } 
  }
  
  if(user.email !== 'nicolaspotier97@gmail.com' && user.email !== 'direccion@cidu.com.co' && user.email !== 'nicolaspotier97@hotmail.com' && user.email !== null) {  
    return (
      <Grid className={classes.rootMessage} container direction="column"  justify="flex-start"  alignItems="stretch">
        <Container maxWidth="sm" className={classes.containerMessage} >
          <Grid item xs={12}  container direction="row" justify="center" alignItems="center">
            <Typography variant={'h3'} align='center' className={classes.textDonwload}>No tienes permisos para gestionar la plataforma</Typography>
          </Grid>
        </Container>
      </Grid>
    )
  }

  return(
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar} > 
          <Tabs variant="fullWidth" centered value={value} onChange={handleTabChange} aria-label="full width tabs example">
            <Tab label="Usuarios" id={`simple-tab-0`} />
            <Tab label="Métricas" id={`simple-tab-1`} />
            <Tab label="Documentos" id={`simple-tab-2`} />
          </Tabs> 
      </AppBar>

      {value === 0 ? <TapPanel> 
        <Grid container >
          <Grid item xs={12} container alignItems={'flex-end'}>
            <Button 
              //fullWidth 
              type="submit" 
              variant="contained" 
              className={classes.submit} 
              onClick={closeSesion}>
              {"Cerrar Sesión"}
            </Button> 
          </Grid>

          <Grid item xs={12} container  className={classes.containerTable}>
            { loading ? <Loader /> :
                  <TableContainer className={classes.rootTable} component={Paper}>
                    <Table className={classes.table}  >
                      <TableHead>
                        <TableRow >
                          <TableCell >
                            <Typography component="h1" variant="h6" className={classes.title}>
                              Usuario
                            </Typography>
                          </TableCell>
                          <TableCell align="right" >
                            <Typography component="h1" variant="body2" className={classes.title}>
                              Id
                                </Typography>
                          </TableCell>
                          <TableCell align="right" className={classes.title}>
                            <Typography component="h1" variant="body2" className={classes.title}>
                              Correo
                                </Typography>
                          </TableCell>
                          <TableCell align="right" className={classes.title}>
                            <Typography component="h1" variant="body2" className={classes.title}>
                              Rol
                                </Typography>
                          </TableCell>
                          <TableCell align="right" className={classes.title}>
                            <Typography component="h1" variant="body2" className={classes.title}>
                              Telefono
                                </Typography>
                          </TableCell>
                          <TableCell align="right" className={classes.title}>
                            <Typography component="h1" variant="body2" className={classes.title}>
                              Estado
                            </Typography>
                          </TableCell> 
                          <TableCell padding={'checkbox'} />
                          <TableCell padding={'checkbox'} />
                        </TableRow>
                      </TableHead>


                      <TableBody>
                        {users.map((row, index) => (
                          <StyledTableRow key={index.toString()}>
                            <TableCell component="th" scope="row" className={classes.text}>{row.userName}</TableCell>
                            <TableCell align="right" className={classes.text}>{row.userCodeId}</TableCell>
                            <TableCell align="right" className={classes.text}>{row.userMail}</TableCell>
                            <TableCell align="right" className={classes.text}>{row.userRole}</TableCell>
                            <TableCell align="right" className={classes.text}>{row.userPhone}</TableCell>
                            <TableCell align="right" className={classes.text}>{row.userState}</TableCell> 
                            <TableCell align="center" className={classes.text}>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                className={classes.icon}
                                onClick={() => deleteUser(row)}>
                                <DeleteOutlineIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell align="center" className={classes.text}>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                className={classes.icon}
                                onClick={() => loadUserUpdate(row)} >
                                <CreateIcon />
                              </IconButton>
                            </TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>} 
          </Grid>


         
        </Grid>

       
        <Fab className={classes.fab} onClick={createNewUser}>
          <AddIcon />
        </Fab>

      </TapPanel>
      : value === 1 ? <TapPanel>
          <Grid container spacing={2}>
            {/* Chart */}
            <Grid item xs={12}>
              <Paper className={clsx(classes.paper, classes.fixedLargeHeight)}>
                <Typography variant={'h5'} className={classes.textDonwload}>Proyectos por Mes</Typography>
                <Grid item xs={12} className={classes.containerDoughnut}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center">
                  <Line  
                      data={{
                        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                        datasets: [
                          {
                            label: 'Proyectos',
                            data: [
                                metrics.timelineProjects.enero,
                                metrics.timelineProjects.febrero,
                                metrics.timelineProjects.marzo,
                                metrics.timelineProjects.abril,
                                metrics.timelineProjects.mayo,
                                metrics.timelineProjects.junio,
                                metrics.timelineProjects.julio,
                                metrics.timelineProjects.agosto,
                                metrics.timelineProjects.septiembre,
                                metrics.timelineProjects.octubre,
                                metrics.timelineProjects.noviembre,
                                metrics.timelineProjects.diciembre, 
                              ],  // metrics.timelineProjects.enero 
                            fill: false, 
                            backgroundColor: '#294374',
                            borderColor: '#33658a88',
                          },
                        ],
                      }}  
                      height={1}
                      width={2} 
                      options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        ],
                      },
                    }} />
                </Grid>
              </Paper>
            </Grid>
            
            {/* Recent Deposits */} 
            <Grid item xs={12} md={4}>
              <Paper className={clsx(classes.paper, classes.fixedHeight)}>
                <Typography variant={'h5'} className={classes.textDonwload}>Población</Typography>
                <Grid item xs={12} className={classes.containerDoughnut}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center">
                  <Doughnut data={{
                    labels: ['Urbano', 'Rural'],
                    datasets: [
                      {
                        label: '# of Votes',
                        data: [metrics.poblation.urbano, metrics.poblation.rural],
                        backgroundColor: [
                          'rgba(51, 101, 138, 0.2)',
                          'rgba(134, 187, 216, 0.2)', 
                        ],
                        borderColor: [
                          'rgba(51, 101, 138, 1)',
                          'rgba(134, 187, 216, 1)', 
                        ],
                        borderWidth: 1, 
                      },
                    ],
                  }}  
                  
                  height={20}
                  width={1} 
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                  }} 
                  />
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className={clsx(classes.paper, classes.fixedHeight)}>
                <Typography variant={'h5'} className={classes.textDonwload}>Tipo Proyectos</Typography>
                <Grid item xs={12} className={classes.containerDoughnut}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center">
                  <Doughnut data={{
                    labels: ['Ph', 'Nph',],
                    datasets: [
                      {
                        label: '# of Votes',
                        data: [metrics.property.ph, metrics.property.nph],
                        backgroundColor: [
                          'rgba(134, 187, 216, 0.2)', 
                          'rgba(51, 101, 138, 0.2)',
                        ],
                        borderColor: [
                          'rgba(134, 187, 216, 1)', 
                          'rgba(51, 101, 138, 1)',
                        ],
                        borderWidth: 1, 
                      },
                    ],
                  }}  
                  
                  height={20}
                  width={1} 
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                  }}


                  />
                </Grid>
              </Paper>
            </Grid>    
            <Grid item xs={12} md={4}>
              <Paper className={clsx(classes.paper, classes.fixedHeight)}>
                <Typography variant={'h5'} className={classes.textDonwload}>Estado Proyectos</Typography>
                <Grid item xs={12} className={classes.containerDoughnut}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center">
                  <Doughnut data={{
                    labels: ['Inicio', 'Visita', 'Control'],
                    datasets: [
                      {
                        label: '# of Votes',
                        data: [metrics.stateProjects.initProjects, metrics.stateProjects.visitProjects,  metrics.stateProjects.qualityProjects],
                        backgroundColor: [
                          'rgba(134, 187, 216, 0.2)', 
                          'rgba(51, 101, 138, 0.2)',
                          'rgba(41, 67, 116, 0.2)',
                        ],
                        borderColor: [
                          'rgba(134, 187, 216, 1)', 
                          'rgba(51, 101, 138, 1)',
                          'rgba(41, 67, 116, 1)',
                        ],
                        borderWidth: 1, 
                      },
                    ],
                  }}  
                  
                  height={20}
                  width={1} 
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                  }}


                  />
                </Grid>
              </Paper>
            </Grid>
          
          </Grid>
      </TapPanel> 
      : <TapPanel>  
          <Grid container direction="column" justify="flex-start"  alignItems="stretch" item xs={12} >
            {downloadDocuments.map(d => 
              <Paper className={classes.paperDownload} key={d.id}>
                <Typography variant={'h5'} noWrap className={classes.textDonwload} gutterBottom>{d.name}</Typography>
                <Button  
                  type="submit" 
                  variant="contained" 
                  className={classes.submit} 
                  onClick={() => createUrldownload(d)}>{"Descargar"}</Button>  
              </Paper>
            )} 
        </Grid>  
      </TapPanel>
      }

      {/* Download modal*/}
      <Loading  
          show={loadingModal} 
          title={'Espere un Momento'}
          handleClose={toggleModal}
          isLoading={true}
          isSuccesfull={false} 
          isUnsuccessful={false}
          isBadRequest={false}
          withOutAction
          messageModal={'Descargando...'}  />

      {/* Modal of create user */}
      <Loading  
        show={userData.statemodal} 
        title={userData.titleModal}
        handleClose={toggleModalNewUser}
        isLoading={userData.isLoading}
        isSuccesfull={userData.isSuccesfull} 
        isUnsuccessful={userData.isUnsuccessful}
        isBadRequest={userData.isBadRequest}
        messageModal={userData.contentmodal} 
        actionBtn={clearData} /> 

      {/* Modal to delete User */}
      <Modal
        open={userDelete.stateModalDelete}
        title={'¡Advertencia!'}
        handleClose={cancelDeleteser}
        handleConfirm={confirmDeleteUser}>
        <Grid item xs={12} component={'span'} >
          <Typography component="h1" variant="h5" align={'center'} className={classes.title}>
            {`¿Desea eliminar el usuario: ${userDelete.userId} ?`}
          </Typography>
        </Grid> 
      </Modal>

      {/* modal to create user inputs */}
      <Modal open={newUserModal} title={userData.isUpdating ? 'Actualizar Usuario' : 'Añadir Usuario'} handleClose={closeNewUserModal} handleConfirm={sendNewUser}>

        <Grid item xs={12} component={'span'} >
            {(userData.userRole === 'coadministrador' || userData.userRole ===  'administrador') &&
            <Grid item xs={12} container direction="column" justify="flex-start" alignItems="stretch" >
              <Grid item xs={12} container direction="row" justify="space-around"  alignItems="center" >
                <input className={classes.submitPhoto} name="userPhoto" required type="file" accept="image/x-png" onChange={handleChange} />
                {userData.photoUrl.length > 0 ? <img src={userData.photoUrl} alt="userFirm" width="140" height="100" />
                : <Typography variant={'h5'} noWrap className={classes.textDonwload} gutterBottom>{'Sin Foto'}</Typography>}
              </Grid>
              <Grid item xs={12} container direction="row" justify="center" alignItems="stretch" >
                {userData.userPhotoError && <FormHelperText className={classes.helperText}>{" "}{userData.userPhotoErrorMessage}</FormHelperText>}
              </Grid>
            </Grid>}
          
            <FormControl fullWidth>
            <TextField
                required
                id="userName"
                fullWidth
                error={userData.userNameError} 
                label="Nombre Completo"
                name="userName"  
                value={userData.userName}
                margin="normal"
                helperText={userData.userNameErrorMessage}
                onChange={handleChange} />
            </FormControl>
         
            <FormControl fullWidth>
            <TextField
                required
                id="userMail"
                fullWidth
                error={userData.userMailError} 
                label="Correo"
                name="userMail"  
                value={userData.userMail}
                margin="normal"
                helperText={userData.userMailErrorMessage}
                onChange={handleChange} />
            </FormControl>
          
            <FormControl className={classes.simpleMarginInput} fullWidth error={userData.userTypeIdError}>
            <InputLabel>Tipo de Identificación *</InputLabel>
            <Select
                name="userTypeId" 
                id="userTypeId"
                required
                value={userData.userTypeId}
                onChange={handleChange}> 
              <MenuItem value="CC">Cédula de Cidadania</MenuItem>
              <MenuItem value="CE">Cédula de Extranjería</MenuItem>
              <MenuItem value="NIT">Número de Identificación Tributaria</MenuItem>
            </Select>
            {userData.userTypeIdError && <FormHelperText className={classes.helperText}>{" "}{userData.userTypeIdErrorMessage}</FormHelperText>}
            </FormControl>

            <FormControl fullWidth>
            <TextField
                required
                id="userId"
                fullWidth
                error={userData.userIdError} 
                label="Numero de Identidad"
                name="userId"  
                value={userData.userId}
                margin="normal"
                helperText={userData.userIdErrorMessage}
                onChange={handleChange} />
            </FormControl>

            <FormControl className={classes.simpleMarginInput} fullWidth error={userData.userRoleError}>
            <InputLabel>Rol *</InputLabel>
            <Select
                name="userRole" 
                id="userRole"
                required
                value={userData.userRole}
                onChange={handleChange}>  
              <MenuItem value="administrador">Administrador</MenuItem>
              <MenuItem value="coadministrador">CoAdministrador</MenuItem>
              <MenuItem value="visitador">Visitador</MenuItem>
              <MenuItem value="consulta">Consulta</MenuItem>
            </Select>
            {userData.userRoleError && <FormHelperText className={classes.helperText}>{" "}{userData.userRoleErrorMessage}</FormHelperText>}
            </FormControl>
       
            <FormControl fullWidth>
              <TextField
                required
                id="userPhone"
                fullWidth
                error={userData.userPhoneError} 
                label="Telefono"
                name="userPhone"  
                value={userData.userPhone}
                margin="normal"
                helperText={userData.userPhoneErrorMessage}
                onChange={handleChange} />
            </FormControl>

            {(userData.userRole === 'coadministrador' || userData.userRole ===  'administrador') &&
            <FormControl fullWidth>
              <TextField
                required
                id="userRaa"
                fullWidth
                error={userData.userRaaError} 
                label="Código RAA"
                name="userRaa"  
                value={userData.userRaa}
                margin="normal"
                helperText={userData.userRaaErrorMessage}
                onChange={handleChange} />
            </FormControl>}
          
            <FormControlLabel
              control={<Checkbox 
                color="primary" 
                name="userState" 
                type="checkbox"
                onChange={handleChange} 
                value={userData.userState} 
                disableRipple={true} 
                checked={userData.userState} />}
              label="Usuario Habilidado?" />
            <FormHelperText error={userData.userStateError} className={classes.helperText}>{userData.userStateErrorMessage}</FormHelperText>
          
          </Grid>
        </Modal>
    </div>
  )
}
