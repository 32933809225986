import React from "react";
import LoadUser from '../loadUser';
//CONTEXT
import { useUser } from '../../context/auth';


export default function Root(props){

  const { loadingUser } = useUser();
  const { children, } = props;

  if (loadingUser) return <LoadUser />
  return <> {children} </>
  
};
 