import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    //minHeight: '-webkit-fill-available',
    //height: '100%', 
    minHeight: '93.1%',
    flexGrow: 1,
    backgroundColor: "#e4e4e4",
  }, 
  container:{
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(0),
    height: '100%',
    backgroundColor: "#e4e4e4",
  }
}));
export default useStyles;
