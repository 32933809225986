import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GuardRoute from "../components/guardRoute";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../theme";
//COMPONENTS
import NoFound from "../pages/noFound";
import Root from "../components/root";
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
//CONTEXT
import { UserProvider } from "../context/auth";
import { MetricsProvider } from "../context/metrics";
import { SnackbarProvider } from "notistack";

function Routes() {
  return ( 
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={2}>
          <UserProvider>
            <MetricsProvider>
              <Root>   
                <Router>
                  <Switch>
                    <GuardRoute type="private" path="/dashboard" component={Dashboard}/>
                    <GuardRoute type="public" exact path="/" component={Login} />
                    <Route type="public" component={NoFound} />
                  </Switch>
                </Router>
              </Root> 
            </MetricsProvider>
          </UserProvider>
        </SnackbarProvider>
      </ThemeProvider> 
  );
}
export default Routes;
