import React from "react";
import { Route, Redirect } from "react-router-dom";
//Context
import { useUser } from "../../context/auth";

export default function GuardRoute(props){
  
  const { user } = useUser();
  const { type, path, ...rest } = props; 
  //console.log('user', user)

  if (!user && type === 'private') return <Redirect to={'/'} />
  else if (user && type === 'public') return <Redirect to={'/dashboard'} />
  return <Route {...rest} /> 
}

