import { Db } from '../firebase';

//Crear
export async function createData(collection, id, data){
    return await Db.collection(collection).doc(id).set(data);
}

//Actualizar
export async function updateData(collection, id, data){
    return await Db.collection(collection).doc(id).update(data);
}

//Obtener
export async function getData(collection, id){
    const userRef =  await Db.collection(collection).doc(id).get(); 
    if (userRef.exists) { 
        return await userRef.data();
    } else { 
        return {
            error: true,
            message: 'Don´t data exist'
        }
    }
}

//Obtener toda la base de datos
export async function getallData(collection){
    const userRef = await Db.collection(collection).get()
    return await userRef 
}

//Borrar
export async function deleteData(collection, id){
    return await Db.collection(collection).doc(id).delete();
}
