import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  containerTittle: {
    marginLeft: theme.spacing(4),
  },
  title: {
    fontFamily: "Century-Gothic",
    color: "#33658a",
    fontWeight: "bold",
  },

  buttonPrincipal: {
    paddingBlockStart: "10px",
    paddingBlockEnd: "10px",
    paddingInlineStart: "40px",
    paddingInlineEnd: "40px",

    backgroundColor: "#33658A",
    "&:hover": {
      backgroundColor: "#33658A",
    },
  },
  buttonSecondary: {
    paddingBlockStart: "10px",
    paddingBlockEnd: "10px",
    paddingInlineStart: "40px",
    paddingInlineEnd: "40px",

    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "red",
    },
  },

  titleButton: {
    color: "white",
    fontFamily: "Century-Gothic",
    fontWeight: "bold",
  },
}));
export default useStyles;
