import React from 'react'
import Grid from "@material-ui/core/Grid"; 
import DialogCidu from "../dialogCidu";
//Icons 
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel"; 
//Spinner
import ReactLoading from "react-loading"; 


export default function Loading(props){
 
  const { 
      show=false, 
      title='Cargando...', 
      handleClose, 
      isLoading=true, 
      isSuccesfull=false, 
      isUnsuccessful=false, 
      isBadRequest=false,
      messageModal,
      withOutAction=false, 
      actionBtn, 
      height=150,
      width=150,
    } = props;
    
  return <DialogCidu
          show={show}
          close={handleClose}
          header={title}
          othercontent={
            <Grid item container direction="row" justify="center" alignItems="center">
              {isLoading && <ReactLoading type="spinningBubbles" color="#86bbd8" height={height} width={width}/>}
              {isSuccesfull && <CheckCircleIcon style={{ color: "#86bbd8", width: "200", height: "200", }}/>}
              {isUnsuccessful && <WarningIcon style={{ color: "#86bbd8", width: "200", height: "200"}}/>}
              {isBadRequest && <CancelIcon style={{color: "#86bbd8", width: "200", height: "200"}}/>}
            </Grid>}
          content={messageModal}
          withOutAction={withOutAction}
          actionBtn={actionBtn}/> 
}
 

