import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button"; 
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "../typography";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./style.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DialogCidu(props) {
  const classes  = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={props.show}
      maxWidth="md"
      onClose={props.close}
      TransitionComponent={Transition}
      className={classes.root}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}
    >
      <DialogTitle id="simple-dialog-title" disableTypography>
        <Typography variant="h4" align="left" className={classes.title}>
          {props.header}
        </Typography>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <Grid container direction="row" justify="space-evenly" alignItems="stretch" className={classes.other}>
          {props.othercontent}
          <DialogContentText gutterBottom className={classes.contentText} variant="h4">
            {props.content}
          </DialogContentText>
        </Grid> 
      </DialogContent>
      { !props.withOutAction && <DialogActions className={classes.gridbutton}>
        <Button autoFocus onClick={props.actionBtn} className={classes.dialogButtom}>
        <Typography component="h2" variant="h6" className={classes.titleButton}>
            Cerrar
        </Typography></Button>
      </DialogActions> }
    </Dialog>
  );
}
 

