import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  rootMessage: {
    height: "100%",
    flexGrow: 1, 
    backgroundImage:  `linear-gradient(rgba(35, 37, 41, 0.3), rgba(35, 37, 41, 0.3)), url(https://source.unsplash.com/featured/building,city,cities)`,
    backgroundPosition: "center",
    backgroundSize: 'cover',
  },
  containerMessage:{
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', 
  },
  appbar: {
    background: "#33658a",
    paddingTop: theme.spacing(2),
    //marginBottom: theme.spacing(2)
  },
  toolbar: {
    background: "red",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 350,
    display: "flex",
    //padding: theme.spacing(1),
  },
  fixedSoloHeight: {
    height: 350,
  },
  fixedLargeHeight: {
    height: 400,
    display: "flex", 
  },
  containerDoughnut: {
    display: "flex",
    height: "10px", //theme.spacing(5),
    padding: theme.spacing(0, 5),
  },
  submit: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    //minWidth: theme.spacing(4),
    //width: theme.spacing(8),
    fontFamily: "Century-Gothic",
    backgroundColor: "#33658A",
    borderRadius: "6px",
    paddingBlockStart: "15px",
    paddingBlockEnd: "15px",
    color: "white",
    "&:hover": {
      backgroundColor: "#33658A",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  submitPhoto: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4), 
    fontFamily: "Century-Gothic",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  helperText: {
    color: 'red',
    fontFamily: "Century-Gothic",
  },
  //Usuarios
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: "#fff",
    backgroundColor: "#33658a",
    "&:hover": {
      backgroundColor: "#294374",
    },
  },
  containerTable: {
    margin: theme.spacing(4, 0),
  },
  //Tabla
  rootTable: {
    marginBottom: theme.spacing(6),
  },
  table: {
    minWidth: 650,
  },

  title: {
    color: "#18202c",
    fontFamily: "Century-Gothic",
    fontWeight: "bold",
  },
  text: {
    fontFamily: "Century-Gothic",
    fontWeight: "bold",
  },
  icon: {
    color: "#ffffff",
    backgroundColor: "#33658a",
    height: theme.spacing(5),
    width: theme.spacing(5),
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#33658a",
    },
  },
  simpleMarginInput: { 
    marginTop: theme.spacing(2),
    //marginBottom: theme.spacing(2),
  },

  //Documents
  paperDownload: {
    display: "flex",
    flexDirection: "row",
    width: "-webkit-fill-available",
    justifyContent: "space-between",
    padding: theme.spacing(0, 8),
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  textDonwload: {
    fontWeight: "bold",
    color: "#33658a",
    textTransform: "capitalize",
  },
}));
export default useStyles;
