import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export default function Copyright(props) {
    const { className } = props
    return (
      <Typography variant="body2" color="textSecondary" align="center" className={className}>
        {'© '}
        <Link color="inherit" href="https://cidu.com.co" >
          CIDU 
        </Link>{' - Consultoria de Ingeniería y Desarrollo Urbano S.A.S - '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }