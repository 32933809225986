import React, { useState, useEffect } from 'react'; 
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl"; 
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid'; 
import Typography from '@material-ui/core/Typography';
import Copyright from '../../components/copyright';
import ciduLogo from '../../assets/img/logo192.png' 
import NormalTextfield from '../../components/normalTextfield'; 
import useStyles from './style';
import bogota from "../../assets/video/bogota.mp4";

//Firebase
import Firebase, { Auth } from '../../firebase';
  
export default function SignIn() {
  const classes = useStyles(); 
  const [rememberMe, setRememberMe] = useState(false);
  const [credentials, setCredentials] = useState({  
    // PhoneCode OTP
    phone: '',
    phoneError: false,
    phoneErrorMessage: '',
    otpCode: '',
    otpCodeError: false,
    otpCodeErrorMessage: '',
    //Confir data code
    confirmationResult: {},
    validateDataCaptcha: false,
  })

  useEffect(() => {
    Auth.useDeviceLanguage(); 
    window.recaptchaVerifier = new Firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      'callback': (response) => {
          console.log("Ok captcha") 
      },
      'expired-callback': () =>  {
        console.log("expired-callback");
      }
    });   
  }, [])

  useEffect(() => {  
    controlPersistance();
  }, [rememberMe])

  //Persistencia de sesion
  const controlPersistance = async () => {
    try { 
      if(rememberMe) await Auth.setPersistence(Firebase.auth.Auth.Persistence.LOCAL)
      else await Auth.setPersistence(Firebase.auth.Auth.Persistence.SESSION)
    } catch (error) {
      console.log('Error', error) 
    }
  }

  //change text
  const handleChange = e => {  
    const { name, value } = e.target;
    setCredentials(prevState => ({
        ...prevState,
        [name]: e.target.type === "checkbox" ? e.target.checked : value
    }));
  }  

  //change persistence
  const handleChangeRemember = e => setRememberMe(e.target.checked)
  
  //send form fot open sesion
  const sendForm = async () => {
    try {
      const status = validateLogin();
      if(!status){
        let appVerifier = window.recaptchaVerifier;
        let userPhone = '+57'+ credentials.phone;
        //let userPhone = '+1' + credentials.phone;
        let statusPhone = await Auth.signInWithPhoneNumber(userPhone, appVerifier)
        //console.log('Status Phone: ', statusPhone)
        setCredentials(prevState => ({
          ...prevState,
          phone: '',
          confirmationResult: statusPhone.verificationId,
          validateDataCaptcha: true
        }))
      }      
    } catch (error) {
      //console.log('New error', error); 
      setCredentials(prevState => ({
        ...prevState, 
        phone: '',
        confirmationResult: {},
        validateDataCaptcha: false
      }))
      grecaptcha.reset(window.recaptchaWidgetId);
    }
  }

  //senc code
  const sendCode = async () => {
    try { 
      const status = validateCode();
      if(!status){ 
        const statusPhone = credentials.confirmationResult;
        const OTP = credentials.otpCode 
        var credential = await Firebase.auth.PhoneAuthProvider.credential(statusPhone, OTP);
        //console.log('Estado de credential', credential)
        await Auth.signInWithCredential(credential);
        //console.log('signInWithCredential', algoo)
        setCredentials(prevState => ({
          ...prevState, 
          phone: '',
          phoneError: false,
          phoneErrorMessage: '',
          otpCode: '',
          otpCodeError: false,
          otpCodeErrorMessage: '',
          confirmationResult: {},
          validateDataCaptcha: false,
        })) 
      }      
    } catch (error) {
      console.log('New error', error); 
      setCredentials(prevState => ({
        ...prevState,
        otpCode: '',
        otpCodeError: false,
        otpCodeErrorMessage: '',
        //confirmationResult: {},
        //validateDataCaptcha: false
      }))
    }
  }

  //Validaciones de OTP entrada de usuario
  const validateCode = () => { 
    let hayErrores = false;
    let numbers = /^[0-9]{6}$/ 
    //Código
    if (numbers.test(credentials.otpCode)) {
      setCredentials(prevState => ({
        ...prevState,
        otpCodeError: false,
        otpCodeErrorMessage: ""
      })) 
    } else { 
      setCredentials(prevState => ({
        ...prevState,
        otpCodeError: true,
        otpCodeErrorMessage: "Código No válido, intentelo de nuevo",
      }))
      hayErrores = true;
    }
    return hayErrores;
  }

  //Validaciones de CELULAR entrada de usuario
  const validateLogin = () => { 
    let hayErrores = false;
    let numbers = /^[0-9]{10}$/ 
    //Telefono
    if (numbers.test(credentials.phone) && (credentials.phone === '3196500341' || credentials.phone === '3014606980' || credentials.phone === '3144501998')) {  // && (credentials.phone === '3196500341' || credentials.phone === '3014606980')
      setCredentials(prevState => ({
        ...prevState,
        phoneError: false,
        phoneErrorMessage: ""
      })) 
    } else { 
      setCredentials(prevState => ({
        ...prevState,
        phoneError: true,
        phoneErrorMessage: "Teléfono Celular No válido, ingrese solo números",
      }))
      hayErrores = true;
    }
    return hayErrores;
  }

  return (   
    <Grid aling='center' className={classes.root}>
      <video autoPlay loop muted id="videoCidu" className={classes.video} src={bogota} type="video/mp4"/>   
      <CssBaseline /> 
      <div className={classes.backdrop} /> 
      <Container className={classes.container}>
        <Grid container item direction="column" justify="center" alignItems="center" >  
          <Grid item className={classes.paper}> 
            <Avatar alt="Cidu" src={ciduLogo} className={classes.avatar}/>
            <Typography component="h2" variant="h5" className={classes.tittle}>
              Iniciar Sesión
            </Typography>
          </Grid> 
          <Grid item container justify="center" className={classes.form}> 
            { !credentials.validateDataCaptcha ? 
            <Grid item xs={11} className={classes.inputContainer}>
              <FormControl fullWidth>
                <NormalTextfield
                    label="Teléfono"
                    type="tel" 
                    margin="normal"
                    fullWidth
                    name="phone"
                    variant="outlined"
                    autoComplete="tel" 
                    error={credentials.phoneError}
                    helperText={credentials.phoneErrorMessage} 
                    value={credentials.phone} 
                    onChange={handleChange} 
                />
              </FormControl>
            </Grid>
            : <Grid item xs={11} className={classes.inputContainer}>
              <FormControl fullWidth>
                <NormalTextfield
                    label="Código"
                    type="password" 
                    margin="normal"
                    fullWidth
                    name="otpCode"
                    variant="outlined" 
                    error={credentials.otpCodeError}
                    helperText={credentials.otpCodeErrorMessage} 
                    value={credentials.otpCode} 
                    onChange={handleChange}/>
              </FormControl>
            </Grid> } 
            <Grid item xs={11}>
              <FormControlLabel  
                control={<Radio  
                  name="rememberMe"
                  type="checkbox"
                  value={rememberMe || ''}
                  checked={rememberMe ? true : false} 
                  disableRipple={true}
                  onChange={handleChangeRemember} 
                  classes={{ root: classes.radiobutton, checked: classes.checked }} />}
                label="Recordarme"  /> 
            </Grid>
            <Grid item xs={11}>
              <Button 
                fullWidth 
                type="submit" 
                variant="contained" 
                className={classes.submit} 
                onClick={credentials.validateDataCaptcha ? sendCode : sendForm}>
                {credentials.validateDataCaptcha ? "Ingresar" : "Continuar"}
              </Button>
              <Copyright className={classes.copy}/>
            </Grid>
          </Grid>
          <div id="recaptcha-container"/>
        </Grid>
      </Container>
    </Grid>
  );
}