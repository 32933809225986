import React from "react";
import Grid from "@material-ui/core/Grid"; 
//Spinner
import ReactLoading from "react-loading";

export default function Loader(props){
  const { height = 200, width = 200 } = props;

  return (
    <Grid item container direction="row" justify="center" alignItems="center">
      <ReactLoading
        type="spinningBubbles"
        color="#294374aa"
        height={height}
        width={width}
      />
    </Grid>
  );
}
